.vgsForm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#cardNumber iframe {
  height: 56px;
}

#astra-credit-card-form iframe {
  width: 100%;
  height: 56px;
}

.vgsForm .Mui-disabled:disabled {
  color: #9b9b9b !important;
}
