.root {
  background: #393939;
  color:#fff;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container {
  max-width: 400px;
}

.title {
  color: #edc926;
  font-family: "ProximaNova", sans-serif;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.description {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  font-size: 28px;
}

.children {
  color: #edc926;
  display: block;
  font-size: 20px;
  font-weight: normal;
  margin-top: 32px;
}

.img {
  max-width: 158px;
  margin-bottom: 32px;
}
