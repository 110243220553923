.vgsForm {
  display: block;
  width: 100%;
}

.vgsForm iframe {
  height: 56px;
  width: 100%;
}

.vgsForm .Mui-disabled:disabled {
  color: #9b9b9b !important;
}
