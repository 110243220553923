
.root {
  background: #fff;
  margin-top: 2rem;
  padding: 2rem;
  text-align: center;
}

.title {
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
}

.text {
  font-size: 14px;
  color: #7F7F7F;
}

.link {
  color: #5C5C5C;
  position: relative;
}

.link:after {
  content: '';
  display: block;
  width: auto;
  height: 1px;
  background: #e8c84d;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}
